@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Syne:wght@400..800&display=swap');

body {
  font-family: "Roboto", Arial, sans-serif !important;
  background-color: #1a1b26;
  color: #d1d5db;
}

/* General */
.section {
  padding: 100px 0;
}
h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.btn-primary {
  background-color: #bd93f9 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #7F63D4 !important;
  color: #1a1b26 !important;
}
.tech-stack {
  margin-bottom: 20px;
}
.tech-banner {
  background-color: #bd93f9;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  cursor: default;
  user-select: none;
}
.tech-banner:hover {
  background-color: #7F63D4;
}
.comment {
  font-family: "Courier New", monospace;
  font-size: 1.2rem;
  color: #6272a4;
  margin: 10px 0;
}

/* Navbar */
.navbar {
  background-color: #1a1b26;
}
.navbar-brand, .nav-link {
  color: #bd93f9 !important;
  font-size: 1rem;
}
.navbar-brand:hover, .nav-link:hover {
  color: #7F63D4 !important;
}
.navbar-brand {
  font-size: 1rem !important;
  padding: 0.5rem 1rem !important;
}

/* Home */
#home {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: #f8f8f2;
  text-align: center;
  background-color: #1a1b26;
}
#home h1 {
  color: #bd93f9;
  font-size: 3.5rem;
  font-weight: bold;
}
#home .tagline {
  font-style: italic;
  color: #bd93f9;
}
.social-icons {
  margin-top: 1rem;
}
.social-icons a {
  color: #bd93f9;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: color 0.3s;
}
.social-icons a:hover {
  color: #7F63D4;
}
.skills {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #f8f8f2;
}

/* Experience */
#experience {
  background-color: #282a36;
  position: relative;
}
.timeline {
  position: relative;
  border-left: 3px solid #bd93f9;
}
.timeline-item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 20px;
}
.timeline-content {
  background-color: #44475a;
  padding: 15px;
  border-radius: 8px;
  color: #d1d5db;
}
.timeline-content li::marker {
  color: #bd93f9;
}
.company {
  color: #D1A7F9 !important;
}

/* Projects */
#projects {
  background-color: #1a1b26;
  color: #d1d5db;
  padding: 50px 0;
}
#projects h2, #experience h2 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #bd93f9;
}
#projects .comment {
  font-style: italic;
  color: #6c757d;
  margin-bottom: 30px;
}
.card {
  border: none;
  transition: transform 0.3s ease;
  background-color: #3a3f5c !important;
}
.card-title {
  color: #d1d5db !important;
  font-weight: 600;
}
.card-text {
  color: #d1d5db !important;
  font-size: 1rem;
}
.card:hover {
  transform: translateY(-10px);
}
.project-image {
  height: 200px;
  object-fit: cover;
}
.project-type {
  font-size: 1rem;
  font-weight: 500;
  color: #d1a7f9;
  margin-bottom: 10px;
}
.btn-primary {
  background-color: #bd93f9; /* Purple for buttons */
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
}

.btn-primary:hover {
  background-color: #d1a7f9; /* Lighter purple on hover */
}

/* Footer */
footer {
  padding: 10px;
  background-color: #1a1b26;
  color: #bd93f9;
  text-align: center;
}

/* Responsiveness */
@media (max-width: 767px) {
  .tech-stack {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}